import React from "react"
import { Container, Carousel } from "react-bootstrap"
import placeholder from "../images/placeholder.png"

const SuccessStories = ({title}) => (
  <section className="component successStories mt-5 py-5 bg-grey">
    <Container fluid>
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-8 text-center">
          <h2 className="h3 mb-5">{title}</h2>
          <Carousel indicators={true} controls={false}>
            <Carousel.Item>
              <div>
                <p><i>A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth. Even the all-powerful.</i></p>
                <p style={{fontFamily: "Georgia", fontSize: 80, marginBottom: 0}}>“</p>
                <div className="d-flex mx-auto align-items-center justify-content-center mb-5">
                  <div>
                    <img src={placeholder} alt="" width="80" height="80" className="avatar" />
                  </div>
                  <div className="pl-3 text-left">
                    <strong>Firma 1</strong>
                    <br/>
                    <span>Zimmerei</span>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </Container>
  </section>
)

export default SuccessStories
