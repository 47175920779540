import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import ScrollToTop from "./scrollToTop"
import { ParallaxProvider } from 'react-scroll-parallax';

const Layout = ({ children, theme, lang }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ParallaxProvider>
        <div className={theme}>
          <Header theme={theme} lang={lang} />

          {children}

          <Footer theme={theme} />
          <ScrollToTop />
        </div>
      </ParallaxProvider>
    )}
  />
)

export default Layout
