import React from "react"
import smartwerker from '../images/logos/logo-smartwerker.png'

const Logo = ({ name, alt }) => {

  const icons = [
    { name: "smartwerker", src: smartwerker, width: "280" }
  ]
  
  return (
    <img src={icons.filter(item => item.name === name)[0].src} alt={alt} width={icons.filter(item => item.name === name)[0].width} />
  )
}

export default Logo