import React from "react"
import { Container } from "react-bootstrap"

const CoreFeature = ({ title, description, btnLabel, img, additionalClass, category, btnTarget }) => (
  <section className={`component coreFeature py-3 my-5 my-md-0 ${additionalClass}`}>
    <Container fluid>
      <div className="row align-items-center">
        <div className="col-12 col-md-4 d-none d-md-block">
          <img src={img} alt="" className="img-fluid" />
        </div>
        <div className="col-12 col-md-8">
          <span className="category">{category}</span>
          <h2 className="h3 mt-3 mb-4">{title}</h2>
          <p>{description}</p>
          <a className="mt-4 d-block" href={btnTarget} target="_blank" rel="noopener noreferrer">{btnLabel}</a>
        </div>
      </div>
    </Container>
  </section>
)

export default CoreFeature