import React from "react"
import SocialLinks from "./socialLinks"

const Footer = () => {
  return (
    <footer className="py-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 d-flex align-items-baseline justify-content-between">
            <span className="copyright-text">COPYRIGHT 2020 SMARTWERKER, ALL RIGHT RESERVED</span>
            <SocialLinks />
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
