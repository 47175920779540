import React from "react"
import { Container } from "react-bootstrap"
// import Form from "./form"

const StartNow = ({ title, description, btnLabel, additionalClass }) => (
  <section className={`component startNow my-5 py-5 ${additionalClass}`}>
    <Container fluid>
      <div className="row align-items-center justify-content-center text-center">
        <div className="col-12 col-md-8">
          <h2 className="h3 mb-4">{title}</h2>
          <p>{description}</p>
          {/* <Form /> */}
          <a className="btn mt-3" href="https://register.smartwerker.app/" target="_blank" rel="noopener noreferrer">{btnLabel}</a>
        </div>
      </div>
    </Container>
  </section>
)

export default StartNow