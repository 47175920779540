import React from "react"
import { Container } from "react-bootstrap"
import img1 from "../images/appshots/img1.png"
import { Parallax } from "react-scroll-parallax"

const Intro = ({titleColored, title, description, btnLabel}) => (
  <section className="component intro bg-grey py-5">
    <Container fluid>
        <div className="row align-items-center">
            <div className="col-12 col-md-6 mt-5 mb-mt-0 order-2 order-md-1">
            <Parallax className="custom-class" y={[20, -20]} tagOuter="figure">
              <img src={img1} alt="" className="img-fluid" />
            </Parallax>
            </div>
            <div className="col-12 col-md-6 order-1 order-md-2">
                <h1 className="mb-4"><span>{titleColored}</span> {title}</h1>
                <p>{description}</p>
                <a className="btn mt-3" href="#start">{btnLabel}</a>
            </div>
        </div>
    </Container>
  </section>
)

export default Intro