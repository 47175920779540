import React, { useState } from 'react'
import SocialLinks from "./socialLinks"
import Logo from "./logo"
import { Link } from "gatsby"
import Navbar from 'react-bootstrap/Navbar'

const siteMap = {
  smartwerker: {
    logo: {
      name: "smartwerker",
      alt: "Smartwerker Logo",
      url: "/"
    },
    de: {
      items: [
        {label: "Über", url: "/de/#about"},
        {label: "Funktionen", url: "/de/#features"},
        {label: "Referenzen", url: "/de/#testimonials"},
        {label: "Kostenlos Testen", url: "/de/#start", prominent: true},
      ]
    },
    en: {
      items: [
        {label: "About", url: "/en/#about"},
        {label: "Features", url: "/en/#features"},
        {label: "Testimonials", url: "/en/#testimonials"},
        {label: "Free Trial", url: "/en/#start", prominent: true},
      ]
    },
    es: {
      items: [
        {label: "Acerca de", url: "/es/#about"},
        {label: "Características", url: "/es/#features"},
        {label: "Referencias", url: "/es/#testimonials"},
        {label: "Prueba gratuita", url: "/es/#start", prominent: true},
      ]
    }
  }
}

const MainNav = ({ theme, lang }) => {
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Navbar variant={null} expand="lg" className={`mainnav ${collapsed ? 'collapsed' : ''}`} onToggle={(value) => setCollapsed(value)}>
      <Navbar.Toggle className="navbar-toggler-right" data-target="#navbarMobile" aria-controls="navbarMobile">
        <span className="icon-bar top-bar"></span>
        <span className="icon-bar middle-bar"></span>
        <span className="icon-bar bottom-bar"></span>
      </Navbar.Toggle>

      <Link to={siteMap[theme].logo.url} className="navbar-brand logo">
        <Logo name={siteMap[theme].logo.name} alt={siteMap[theme].logo.alt} />
      </Link>
      
      <div className="navbar-collapse d-none d-lg-flex justify-content-end">
        <ul className="nav navbar-nav">
          {siteMap[theme][lang].items.map((item, index) => (
            <li key={index} className="nav-item">
              <Link to={item.url} className={`nav-link navHover ${item.prominent ? 'prominent' : ''} ${index === 0 ? 'pl-0' : ''}`}>{item.label}</Link>
            </li>
          ))}
        </ul>
      </div>

      <Navbar.Collapse bsPrefix="collapse" id="navbarMobile">
        <ul className="nav navbar-nav">
          {siteMap[theme][lang].items.map((item, index) => (
            <li key={index} className={`nav-item`}>
              <Link to={item.url} className={`nav-link navHover ${item.prominent ? 'prominent' : ''} ${index === (siteMap[theme][lang].items.length - 1) ? '' : ''}`}>{item.label}</Link>
            </li>
          ))}
        </ul>

        <SocialLinks mobileNav={true} />
      </Navbar.Collapse>
    </Navbar>
  )
}

export default MainNav
