import React from "react"
import { Container } from "react-bootstrap"
import MainNav from "./mainNav"

const Header = ({ theme, lang }) => (
  <header className="bg-grey">
    <Container fluid>
      <MainNav theme={theme} lang={lang} />
    </Container>
  </header>
)

export default Header
