import React from "react"
import { Container } from "react-bootstrap"
import bg2 from "../images/bg2.png"
import playStore from "../images/playStore.png"
import appStore from "../images/appStore.png"

const DownloadInStores = ({title, description, }) => (
  <section className="component downloadInStores bg-grey">
    <Container fluid>
      <div className="row align-items-center">
        <div className="col-12 col-md-6 py-md-5">
          <h2 className="h4 mb-5">{title}</h2>
          <p>{description}</p>
          <div className="d-flex mt-5">
            <a href="https://apps.apple.com/de/app/smartwerker/id1478263622" target="_blank" rel="noopener noreferrer" className="pr-5">
              <img src={appStore} alt="" width="150" />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.appwerker.smartwerker&hl=de" target="_blank" rel="noopener noreferrer">
              <img src={playStore} alt="" width="150" />
            </a>
          </div>
        </div>
        <div className="col-12 col-md-6 position-relative d-none d-lg-block">
          <img src={bg2} alt="" className="bg" />
        </div>
      </div>
    </Container>
  </section>
)

export default DownloadInStores