import React from "react"
import { Container } from "react-bootstrap"

const FullScreenImage = ({ img, additionalClass }) => (
  <section className={`component fullScreenImage py-3 ${additionalClass}`}>
    <Container fluid>
      <div className="row align-items-center justify-content-center">
        <div className="col-12 col-md-8">
          <img src={img} alt="" className="img-fluid" />
        </div>
      </div>
    </Container>
  </section>
)

export default FullScreenImage