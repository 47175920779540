import React from "react"
import facebook from '../images/icons/facebook.svg'
import youtube from '../images/icons/youtube.svg'
import instagram from '../images/icons/instagram.svg'
import scrollArrowUp from '../images/icons/scroll-arrow-up.svg'
import phone from '../images/icons/phone.svg'
import chevronLeft from '../images/icons/chevron-left.svg'
import chevronRight from '../images/icons/chevron-right.svg'

const Icon = ({ name, width, height }) => {

  const icons = [
    { name: "facebook", icon: facebook },
    { name: "youtube", icon: youtube },
    { name: "instagram", icon: instagram },
    { name: "scrollArrowUp", icon: scrollArrowUp },
    { name: "phone", icon: phone },
    { name: "chevronLeft", icon: chevronLeft },
    { name: "chevronRight", icon: chevronRight },
  ]

  const currentIcon = icons.filter(item => item.name === name)[0]

  if (!currentIcon) {
    return null
  }
  
  return (
    <svg width={width} height={height}>
      <use xlinkHref={`#${currentIcon.icon.id}`} />
    </svg>
  )
}

export default Icon