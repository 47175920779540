import React from "react"
import Icon from "./icon"

const SocialLinks = ({ mobileNav }) => (
  (mobileNav === true) ? (
    <ul className="nav social-nav mt-4 align-items-center justify-content-center d-flex d-md-none">
      <a href="https://www.facebook.com/smartwerkerapp" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="facebook" width="30" height="30" />
      </a>
      <a href="https://www.instagram.com/smartwerker/" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="instagram" width="30" height="30" />
      </a>
      <a href="https://www.youtube.com/channel/UCxYD1v4LDIRJccWiOHT3l7w" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="youtube" width="30" height="30" />
      </a>
    </ul>
  ) : (
    <ul className="nav social-nav align-items-center d-none d-md-flex">
      <a href="https://www.facebook.com/smartwerkerapp" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="facebook" width="30" height="30" />
      </a>
      <a href="https://www.instagram.com/smartwerker/" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="instagram" width="30" height="30" />
      </a>
      <a href="https://www.youtube.com/channel/UCxYD1v4LDIRJccWiOHT3l7w" className="nav-item mx-1" target="_blank" rel="noopener noreferrer">
        <Icon name="youtube" width="30" height="30" />
      </a>
    </ul>
  )
)

export default SocialLinks
