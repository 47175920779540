import React from "react"
import { Container } from "react-bootstrap"
import { ParallaxBanner } from 'react-scroll-parallax'
import bg3 from "../images/bg3.jpg"
import ModalVideo from 'react-modal-video'

class AppVideo extends React.Component{ 

  constructor () {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal () {
    this.setState({isOpen: true})
  }

  render () {
    const { title, descripton, videoId } = this.props
    return (
      <section className="component appVideo my-5">
        <ParallaxBanner
          className="align-items-center d-flex"
          layers={[
              {
                  image: bg3,
                  amount: 0.4,
              }
          ]}
          style={{
              height: '550px',
          }}
        >
          <Container fluid >
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-8 item text-center mb-5 mb-md-0">
                <h2 className="h1 mb-4">{title}</h2>
                <p>{descripton}</p>
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={videoId} onClose={() => this.setState({isOpen: false})} />
                <button onClick={this.openModal} className="playButton">play</button>
              </div>
            </div>
          </Container>
        </ParallaxBanner>
    </section>
    )
  }
}

export default AppVideo
